import styled from 'styled-components'

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .wrapperCardProduct {
        display: flex;
        justify-content: center;
        align-items: space-around;
        flex-wrap: wrap;

        .containerCardProduct {
            flex-basis: 33%;
            margin: 10px 0px;
        }
    }

    .formSearch {
        display: flex;
        margin: 30px 0px 10px;
        height: 40px;

        .inputSearch {
            width: 60vw;
            max-width: 700px;
            height: 100%;
            border-radius: 10px 0px 0px 10px;
            border: 1px solid #393737;

            padding: 10px 20px;

            :focus {
                outline: none;
                ~ .btnSearch {
                    background-color: #dd4b39;
                    color: #f1f1f1;
                }
            }
        }

        .btnSearch {
            padding: 10px 20px;
            border: 1px solid #393737;
            border-radius: 0px 10px 10px 0px;

            background-color: #c4c4c4;
            color: #393737;
            font-weight: bold;
            font-size: 15px;

            transition: all 0.2s ease-in;

            :hover {
                cursor: pointer;
                transform: scale(1.05);
                background-color: #dd4b39;
                color: #f1f1f1;
            }

            :focus {
                outline: none;
            }
        }
    }

    .containerPagination {
        .pagination {
            margin: 30px auto;
            display: flex;
            list-style: none;
            outline: none;
        }
        .pagination > .active > a {
            background-color: #393737;
            border-color: #393737;
            color: #fff;
        }
        .pagination > li > a {
            border: 1px solid #393737;
            padding: 5px 10px;
            outline: none;
            cursor: pointer;
        }
        .pagination > .active > a,
        .pagination > .active > span,
        .pagination > .active > a:hover,
        .pagination > .active > span:hover,
        .pagination > .active > a:focus,
        .pagination > .active > span:focus {
            background-color: #393737;
            border-color: #393737;
            outline: none;
        }
        .pagination > li > a,
        .pagination > li > span {
            color: #393737;
        }
        .pagination > li:first-child > a,
        .pagination > li:first-child > span,
        .pagination > li:last-child > a,
        .pagination > li:last-child > span {
            border-radius: unset;
        }
    }

    .containerWithoutSearch {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0px;

        color: #aaa9a9;
        .iconExclamationSearch {
            font-size: 90px;
            margin-bottom: 10px;
        }
    }
`
