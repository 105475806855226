import React, { useState } from 'react'
import { FaSearch, FaExclamation, FaTimes } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

import Layout from '../layout'
import SEO from '../utils/seo'
import { API } from '../utils/api'

import SearchStyles from '../assets/styles/pages/search'
import BannerDivider from '../components/bannerDivider'
import Loading from '../components/loading'
import { CardCatalog } from '../components/cards'

const SearchPage = () => {
    const [isLoading, setIsLoading] = useState(false),
        [searchInput, setSearchInput] = useState(''),
        [result, setResult] = useState('')

    async function handleSearch(event, pageCurrent) {
        event && event.preventDefault()

        if (searchInput !== '') {
            setIsLoading(true)
            await API.get(`/searchProduct/${searchInput}/${pageCurrent}`)
                .then((resp) => {
                    setResult({
                        products: resp.data.products,
                        pageCount: Math.ceil(resp.data.count / 1),
                    })
                })
                .catch((e) => setResult(e.message))
            setIsLoading(false)
        }
    }

    const handlePageClick = (e) => {
        handleSearch('', e.selected)
    }

    return (
        <Layout>
            <SEO title="Pesquisas" />
            <BannerDivider title="Pesquisa" subtitle="Facilidade para o cliente" />

            <SearchStyles className="col-10">
                <form className="formSearch" onSubmit={(event) => handleSearch(event, 0)}>
                    <input
                        type="text"
                        name="search"
                        className="inputSearch"
                        placeholder="Digite o nome do produto..."
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <button type="submit" className="btnSearch">
                        <FaSearch />
                    </button>
                </form>

                {result === '' ? (
                    <div className="containerWithoutSearch">
                        <FaExclamation className="iconExclamationSearch" />
                        <p className="font-paragraph">Pesquise o produto que deseja...</p>
                    </div>
                ) : isLoading ? (
                    <Loading />
                ) : (
                    <div className="wrapperCardProduct">
                        {result.products.length > 0 ? (
                            result.products.map((i) => (
                                <div className="containerCardProduct" key={i.id}>
                                    <CardCatalog
                                        title={i.name}
                                        description={i.resume}
                                        imageCard={i.image?.url}
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="containerWithoutSearch">
                                <FaTimes className="iconExclamationSearch" />
                                <p className="font-paragraph">
                                    Não encontramos produtos com esse nome...
                                    <br />
                                </p>
                            </div>
                        )}
                    </div>
                )}

                {result.pageCount > 0 ? (
                    <div className="containerPagination">
                        <ReactPaginate
                            previousLabel={'Anterior'}
                            nextLabel={'Próximo'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={result.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(e) => handlePageClick(e)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}
            </SearchStyles>
        </Layout>
    )
}

export default SearchPage
